import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ImageUpload() {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]); // Array to hold uploaded files
  const [existingFiles, setExistingFiles] = useState([]); // Array to hold existing files from the repository
  const token = process.env.REACT_APP_GITHUB_TOKEN; // Your GitHub token
  const repoOwner = 'saneenv'; // Your GitHub username
  const repoName = 'geniushomebuilders'; // Your repository name
  const branch = 'master'; // Branch to upload the file to

  useEffect(() => {
    fetchExistingFiles();
  }, []);

  const fetchExistingFiles = async () => {
    try {
      const response = await axios.get(
        `https://api.github.com/repos/${repoOwner}/${repoName}/contents/uploads`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      setExistingFiles(response.data);
    } catch (error) {
      console.error('Error fetching existing files:', error);
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      setUploadStatus('Please select a file to upload.');
      return;
    }

    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64data = reader.result.split(',')[1]; // Get base64 string
      const filePath = `uploads/${file.name}`; // Define the path in the repo

      try {
        const response = await axios.put(
          `https://api.github.com/repos/${repoOwner}/${repoName}/contents/${filePath}`,
          {
            message: `Upload ${file.name}`,
            content: base64data,
            branch: branch,
          },
          {
            headers: {
              Authorization: `token ${token}`,
            },
          }
        );
        setUploadStatus('File uploaded successfully');
        fetchExistingFiles(); // Refresh the existing files after upload
      } catch (error) {
        console.error('Error uploading file:', error);
        setUploadStatus('File upload failed');
      }
    };

    reader.readAsDataURL(file); // Read the file as a data URL
  };

  const handleDelete = async (filePath, sha) => {
    try {
      await axios.delete(
        `https://api.github.com/repos/${repoOwner}/${repoName}/contents/${filePath}`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
          data: {
            message: `Delete ${filePath}`,
            sha: sha,
            branch: branch,
          },
        }
      );
      setUploadStatus('File deleted successfully');
      fetchExistingFiles(); // Refresh the existing files after deletion
    } catch (error) {
      console.error('Error deleting file:', error);
      setUploadStatus('File deletion failed');
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Upload File or Image</h1>
      <form onSubmit={handleSubmit} style={styles.form}>
        <input type="file" onChange={handleFileChange} required style={styles.input} />
        <button type="submit" style={styles.button}>Upload</button>
      </form>
      {uploadStatus && <p style={styles.status}>{uploadStatus}</p>}
      
      {/* Existing files section */}
      {existingFiles.length > 0 && (
        <div style={styles.uploadedFileContainer}>
          <h3 style={styles.uploadedFileHeader}>Existing Files:</h3>
          {existingFiles.map((uploadedFile) => (
            <div key={uploadedFile.path} style={styles.uploadedFileName}>
              <p>{uploadedFile.name}</p>
              <button 
                onClick={() => handleDelete(uploadedFile.path, uploadedFile.sha)} 
                style={styles.deleteButton}
              >
                Delete
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundColor: '#f4f4f4',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
  header: {
    color: '#333',
    marginBottom: '20px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
  },
  input: {
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    width: '300px',
    fontSize: '16px',
  },
  button: {
    padding: '10px 20px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#007bff',
    color: 'white',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  status: {
    marginTop: '10px',
    fontWeight: 'bold',
  },
  uploadedFileContainer: {
    marginTop: '20px',
    padding: '10px',
    backgroundColor: '#e7f7ff',
    borderRadius: '5px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  },
  uploadedFileHeader: {
    color: '#007bff',
  },
  uploadedFileName: {
    fontWeight: 'bold',
  },
  deleteButton: {
    marginLeft: '10px',
    backgroundColor: '#dc3545',
    color: 'white',
    border: 'none',
    padding: '5px 10px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default ImageUpload;
