import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ProjectCard from "../Elements/ProjectCard";

export default function Pricing() {
  const [images, setImages] = useState([]);
  const repoOwner = 'saneenv';
  const repoName = 'geniushomebuilders';
  const branch = 'master';

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch(
          `https://api.github.com/repos/${repoOwner}/${repoName}/contents/uploads?ref=${branch}`, 
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_GITHUB_TOKEN}`, // Correct token format
            },
          }
        );
  
        if (!response.ok) {
          const errorData = await response.json();
          console.error('Error fetching images:', errorData);
          return;
        }
  
        const data = await response.json();
        if (!Array.isArray(data)) {
          console.error('Expected an array but got:', data);
          return;
        }
  
        console.log('Response Data:', data);
  
        // Extract download_url for image files
        const imageFiles = data
          .filter(file => /\.(jpg|jpeg|png|gif)$/.test(file.name))
          .map(file => file.download_url);
  
        setImages(imageFiles);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };
  
    fetchImages();
  }, [repoOwner, repoName, branch]);
  

  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Works</h1>
            <p className="font13">
              Explore our portfolio of beautifully crafted homes, each designed with precision and care. Take a look at
              some of our favorite builds below, from modern homes to traditional designs.
            </p>
          </HeaderInfo>
          <ProjectsWrapper className="flexSpaceNull">
            {images.map((image, index) => (
              <ProjectBox key={index}>
                <ProjectCard image={image} />
              </ProjectBox>
            ))}
          </ProjectsWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

// Styling as defined previously
const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;

const HeaderInfo = styled.div`
  margin-bottom: 50px;
  text-align: center;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const ProjectsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  @media (max-width: 860px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ProjectBox = styled.div`
  width: 33.3%;
  overflow: hidden;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
  }
`;
