import React from "react";
import styled from "styled-components";

export default function ProjectCard({ image }) {
  return (
    <CardWrapper>
      <Image src={image} alt="Project Image" />
    </CardWrapper>
  );
}

const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
