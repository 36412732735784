import React from "react";
import styled from "styled-components";
// Assets
import ContactImg1 from "../../assets/img/contact-1.jpeg";
import ContactImg2 from "../../assets/img/contact-2.jpeg";
import ContactImg3 from "../../assets/img/contact-3.jpeg";

export default function Contact() {
  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Let's get in touch</h1>
            <p className="font13">
              Reach out to us for any inquiries, collaborations, or to get a quote for your dream home.
              <br />
              We're here to assist you in every step of your home-building journey.
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <ContactDetails>
                <h2 className="font20 extraBold">Contact Information</h2>
                <p className="font15">Builder Name: Md Rafeek</p>
                <p className="font15">Phone: <a href='tel:+919641970085'>+91 9641970085</a></p>
                <p className="font15">Email: <a href="mailto:rf@geniushomebuilders.com">rf@geniushomebuilders.com</a>
                </p>
                <p className="font15">Address: Kamanam, pattikad, Perinthalmanna, Malappuram, kerala.</p>
              </ContactDetails>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
              <div style={{ width: "50%" }} className="flexNullCenter flexColumn">
                <ContactImgBox>
                  <img src={ContactImg1} alt="office" className="radius6" style={{width:'100%',height:'100%'}} />
                </ContactImgBox>
                <ContactImgBox>
                  <img src={ContactImg2} alt="office" className="radius6" style={{width:'100%',height:'100%'}} />
                </ContactImgBox>
              </div>
              <div style={{ width: "50%" }}>
                <div style={{ marginTop: "100px" }}>
                  <img src={ContactImg3} alt="office" className="radius6" style={{width:'100%',height:'100%'}} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const ContactDetails = styled.div`
  padding: 70px 0 30px 0;
  p {
    margin-bottom: 15px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
