import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";
import TestimonialSlider from "../Elements/TestimonialSlider";

export default function Blog() {
  return (
    <Wrapper id="blog">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Home Building Insights</h1>
            <p className="font13">
              Stay updated with the latest trends and tips for building your dream home.
              <br />
              From architecture to interior design, explore valuable insights from our team of experts.
            </p>
          </HeaderInfo>
         
        
        </div>
      </div>
      <div className="lightBg" style={{padding: '50px 0'}}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">What Our Clients Say</h1>
            <p className="font13">
              Hear from homeowners who trusted us to build their dream homes.
              <br />
              Their stories and experiences speak to our commitment to quality and craftsmanship.
            </p>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
